var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GeneralLayoutVue',{scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('p',{staticClass:"font-bold text-xl text-center"},[_vm._v(_vm._s(_vm.$t('adminPanel.navbar.testimonials')))]),(_vm.isLoadingTestimonials)?_c('Spiner'):_vm._e(),_c('router-link',{staticClass:"my-4 block px-4 py-1",attrs:{"to":{
        name: 'admin-testimonial-detail',
        params: { id: 'new' },
        hash: '#details',
      }}},[_c('div',{staticClass:"cursor-pointer p-1 bg-my-blue-primary text-white "},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('adminPanel.testimonials.newTestimonial'))+" ")]),_vm._v(" "),_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)]),_vm._l((_vm.GETTER_GET_TESTIMONIALS),function(testimonial){return _c('router-link',{key:testimonial.id,attrs:{"to":{
        name: 'admin-testimonial-detail',
        params: { id: testimonial.id },
        hash: '#details',
      },"active-class":"active-class-admin py-2 "}},[_c('div',{staticClass:"cursor-pointer p-1 hover:bg-my-blue-primary hover:text-white border-t border-b"},[_c('span',[_c('span',{staticClass:"font-black"},[_vm._v(_vm._s(testimonial.id)+".- ")]),_vm._v(" "+_vm._s(testimonial.name))])])])})]},proxy:true},{key:"main",fn:function(){return [(_vm.$router.currentRoute.path === '/testimonials')?_c('p',{staticClass:"my-title text-3xl"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-map-location-dot","id":"zoneIcon"}})],1):_vm._e(),_c('router-view')]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }